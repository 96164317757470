import React, { useRef, useEffect, useState, useContext } from "react";
import { Card, Flex, Modal, Button, Image } from "antd";
import { Line } from 'react-chartjs-2';
import useFeaturesModels from '../hooks/useFeaturesModels';
import { UserContext } from '../others/UserContext';
import { Main } from "../layouts";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip
);

const Dashboard = () => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [features, setFeatures] = useState([]);
  const [signals, setSignals] = useState([]);
  const [models, setModels] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showGraph, setShowGraph] = useState(false);
  const [graphUrl, setGraphUrl] = useState('');
  const [modalData, setModalData] = useState({
    data: {
      labels: [],
      datasets: []
    }
  })
  const context = useContext(UserContext);
  const { model: modelModels, api } = useFeaturesModels(context.token);
  const refDiv = useRef()
  const offset = 100;

  useEffect(() => {
    const scrollToHashElement = () => {
      const { hash } = window.location;
      const elementToScroll = document.getElementById(hash?.replace("#", ""));

      if (!elementToScroll) return;
      console.log('scroll', elementToScroll.offsetTop - offset)
      refDiv.current.scrollTo({
        top: elementToScroll.offsetTop - offset,
        behavior: "smooth"
      });
    };
    //debugger;
    setTimeout(scrollToHashElement, 1000);
    window.addEventListener("hashchange", scrollToHashElement);
    return window.removeEventListener("hashchange", scrollToHashElement);
  }, [isLoaded]);

  useEffect(() => {
    async function fetchData() {
      let fs = await modelModels.getList({
        filter: {
        },
        token: context.token
      })

      fs.forEach(f => {
        f.models.forEach(m => m.feature_id = f.id)
      });

      fs.sort((a, b) => {
        if (a.id < b.id) return -1
        return 1;
      })

      let ms = fs.map(f => f.models)
      ms = ms.flat()

      ms.sort((a, b) => {
        if (a._id.signal_id < b._id.signal_id) return -1;
        if (a._id.signal_id > b._id.signal_id) return 1;
        if (a._id.name < b._id.name) return -1;
        return 1;
      })

      let feats = [];
      let sigs = [];
      for (let m of ms) {
        if (feats.indexOf(m.feature_id) == -1) {
          feats.push(m.feature_id)
        }
        if (sigs.findIndex(s => s.id == m._id.signal_id) == -1) {
          sigs.push({ feature_id: m.feature_id, id:m._id.signal_id} )
        }
        m.results.sort((a, b) => {
          return a.days - b.days
        })
        m.figures.sort((a, b) => {
          return a.days - b.days
        })

        m.results = m.results.map(mr => ({
          days: mr.days,
          R2: Math.round((mr.r2 + Number.EPSILON) * 100) / 100
        }))
      }

      console.log('feats', feats)
      console.log('signals', sigs)
      console.log('models', ms)
      setFeatures(feats)
      setSignals(sigs);
      setModels(ms)
      setIsLoaded(true);
    }
    fetchData();
  }, []);

  return (
    <Main
      cantView={!context?.roles?.chg}>
      {
        !isLoaded &&
        <div></div>
      }
      {isLoaded &&
        <div style={{ height: '100%', overflow: 'scroll' }} ref={refDiv}><Flex wrap gap="middle">
          {
            features.map((feat) => {
              return <Card
                id={feat}
                bordered
                key={`F-${feat}`}
                title={feat}
                headStyle={{
                  backgroundColor: '#22d3ee'
                }}
                bodyStyle={{
                  backgroundColor: '#cffafe'
                }}
              >
                {
                  signals.filter(s => s.feature_id==feat).map((sig, sigIndex) => {
                    return <Card
                      id={sig.id}
                      bordered
                      key={`S-${sig.id}`}
                      title={sig.id}
                      headStyle={{
                        backgroundColor: '#22d3ee'
                      }}
                      bodyStyle={{
                        backgroundColor: '#cffafe'
                      }}
                    >
                      <Flex wrap gap="middle">
                        {
                          models.filter(m => m._id.signal_id == sig.id).map((m, index) => {
                            let data = {
                              labels: m.results.map(r => {
                                return `${r.days}`
                              }),
                              datasets: [{
                                label: 'R2',
                                data: m.results.map(r => r.R2),
                                borderWidth: 1,
                                borderColor: 'rgb(53, 162, 235)',
                                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                                pointBackgroundColor: function (context) {
                                  var index = context.dataIndex;
                                  var value = context.dataset.data[index];
                                  return value < 0.4 ? 'red' :  // draw negative values in red
                                    value < 0.6 ? 'orange' :    // else, alternate values in blue and green
                                      'green';
                                }
                              }]
                            }
                            return <Card
                              className="w-80"
                              onClick={async () => {
                                setModalData({
                                  signal_id: sig.id,
                                  name: m._id.name,
                                  figures: m.figures,
                                  data
                                });
                                setShowModal(true);
                              }}
                              key={`M-${sigIndex}-${index}`}
                              type="inner"
                              title={m._id.name}>
                              <Line
                                data={data}
                                options={{
                                  scales: {
                                    y:
                                    {
                                      min: 0,
                                      max: 1,
                                      stepSize: 0.2,
                                    },
                                    x:
                                    {
                                      min: 1,
                                      max: 30,
                                      stepSize: 5,
                                    },
                                  }
                                }}
                              />
                            </Card>
                          })
                        }
                      </Flex>
                    </Card>
                  })
                }
              </Card>
            })
          }
        </Flex>
          <Modal
            title={`*${modalData.signal_id} - Modelo ${modalData.name}`}
            width={'80%'}
            open={showModal}
            onOk={() => {
              setShowModal(false);
            }} onCancel={() => {
              setShowModal(false);
            }}
            footer={[
              <Button
                key='submit'
                type='primary'
                onClick={() => {
                  setShowModal(false);
                }}>OK</Button>
            ]}
          >
            {showModal &&
              <Line
                data={modalData.data}
                options={{
                  scales: {
                    y:
                    {
                      min: 0,
                      max: 1,
                      stepSize: 0.2,
                    },
                    x:
                    {
                      min: 1,
                      max: 30,
                      stepSize: 5,
                    },
                  },
                  onClick: function (evt, element) {
                    if (element.length > 0) {
                      let figure = modalData.figures[element[0].index]
                      setGraphUrl(`${api}_binaries/${figure.figure}?database=chg&token=${context.token}`)
                      setShowGraph(true);
                    }
                  },
                }}
              />
            }
          </Modal>
          <Modal
            title={`${modalData.signal_id} - Modelo ${modalData.name}`}
            open={showGraph}
            onOk={() => {
              setShowGraph(false);
            }} onCancel={() => {
              setShowGraph(false);
            }}
            footer={[
              <Button
                key='submit'
                type='primary'
                onClick={() => {
                  setShowGraph(false);
                }}>OK</Button>
            ]}
          >
            <Image
                  width={300}
                  src={graphUrl}
                />
          </Modal>
        </div>
      }
    </Main>
  );
};

export default Dashboard;
