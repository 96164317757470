import React, { useContext, useMemo, useState } from "react";
import { Typography, Button, Form, Modal, Row, Col } from "antd";
import { UserContext } from '../others/UserContext';
import { DateTime } from 'luxon';
import DatatableMongo from "../components/DatatableMongo.js";
import useFeatures, { featureClassOptions } from '../hooks/useFeatures';
import useSignals from '../hooks/useSignals';
import useData from '../hooks/useData';
import { Link } from "react-router-dom";
import { Standard, Sidebar } from "../layouts";
import RegisterForm from "../components/RegisterForm.js";
import Colors from '../colors.json';
import { useSnackbar } from "notistack";

const ViewFeatures = () => {
  const context = useContext(UserContext);
  const { model: modelFeatures, columns: colFeatures, api, authHeader, axios } = useFeatures(context.token);
  const { model: modelSignals } = useSignals(context.token);
  const [modalFeature, setModalFeature] = useState({});
  const { model: modelData } = useData(context.token);
  const [searching, setSearching] = useState(false);
  const { enqueueSnackbar } = useSnackbar();


  const getLatestData = async () => {
    enqueueSnackbar('Buscando datos...', { variant: 'info' })
    setSearching(true);

    try {
      let feats = await modelFeatures.getList({
        filter: {},
        projection: {
          id: 1,
          featureClass: 1,
          featureType: 1
        },
        token: context.token
      })

      for (let feat of feats) {
        let sgnls = await modelSignals.getList({
          filter: {
            featureId: feat.id
          },
          token: context.token
        })
        let last = false;
        for (let reg of sgnls) {
          let flt = {}
          if (!Array.isArray(reg.signals)) {
            reg.signals = []
          }
          if (reg.signals.length === 1) {
            flt = JSON.parse(reg.signals[0].filter);
          }
          else if (reg.signals.length > 1) {
            let subflsts = []
            for (let s of reg.signals) {
              let sf = JSON.parse(s.filter);
              if (s.fromDate && s.toDate) {
                sf['$and'] = [
                  { [reg.dateField]: { $gte: s.fromDate } },
                  { [reg.dateField]: { $lte: s.toDate } }
                ]
              }
              else if (s.fromDate) {
                sf[reg.dateField] = { $gte: s.fromDate }
              }
              else if (s.toDate) {
                sf[reg.dateField] = { $lte: s.toDate }
              }
              subflsts.push(sf);
            }
            flt = { $or: subflsts };
          }

          let reg_latest = await modelData.getList({
            collection: reg.collection,
            limit: 1,
            filter: flt,
            sort: { [reg.dateField]: -1 },
            projection: {
              [reg.dateField]: 1
            },
            token: context.token
          })

          if (reg_latest.length > 0 && (!last || last < reg_latest[0][reg.dateField])) {
            last = reg_latest[0][reg.dateField]
          }
        }
        let fromDate = last ? DateTime.fromISO(last).plus({ days: 1 })
          : DateTime.fromISO('2000-01-01');

        let changes = await modelFeatures.addSignalsData({
          feature: feat,
          fromDate: fromDate.toISO(),
          token: context.token,
          modelSignals
        })
        console.log(feat.id, changes)
      }
      setSearching(false);
      enqueueSnackbar("Terminado", { variant: 'success' })
    }
    catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' })
    }
    finally {
      setSearching(false)
    }

  };

  const columns = useMemo(() => (
    [
      ...Object.values(colFeatures),
      {
        title: 'Acción',
        dataIndex: 'id',
        render: (v, row) => {
          return <><Link
            to={`/signals?featureId=${v}`}>
            <Button>Señales</Button>
          </Link>
            <Link
              to={`/feature/${row._id}`}>
              <Button>Edita</Button>
            </Link>
          </>
        }
      },
    ]), [])


  const { Title } = Typography;

  return <Standard
    header={<Title>Elementos</Title>}
    headerRight={
      <>
        <Button
          disabled={searching}
          type='primary'
          onClick={getLatestData}
          style={{ marginLeft: '1em', marginTop: '1.2em', marginRight: '1em', backgroundColor: Colors.warning.backgroundColor }}>
          Actualiza datos
        </Button>
        <Button
          type='primary'
          onClick={() => {
            setModalFeature(prev => ({
              ...prev,
              show: true
            }))
          }}
          style={{ marginLeft: '1em', marginTop: '1.2em', marginRight: '1em', backgroundColor: Colors.warning.backgroundColor }}>
          Nuevo elemento
        </Button>
      </>}
    left={<Sidebar routes={context.routes.filter(r => r.bigIcon)} />}
  >
    <DatatableMongo
      columns={columns}
      model={modelFeatures}
      keyField='_id'
      token={context.token}
      sort={{ id: 1 }}
    />
    <Modal
      title="Nuevo elemento"
      width={800}
      open={modalFeature.show}
      onOk={async () => {
        let data = {
          ...modalFeature
        }
        delete data.show;
        await modelFeatures.create({
          data,
          token: context.token
        });
        setModalFeature(prev => ({
          ...prev,
          show: false
        }));
      }}
      onCancel={() => {
        setModalFeature(prev => ({
          ...prev,
          show: false
        }))
      }}>
      <RegisterForm
        style={{ width: '100%' }}
        fields={colFeatures}
        noDisableds
        register={modalFeature}
        onChange={({ name, value }) => {
          setModalFeature(prev => ({
            ...prev,
            [name]: value
          }))
        }} />
    </Modal>
  </Standard>

};

export default ViewFeatures;
