import Colors from '../colors.json';

const FACTOR = 72 / 25.4;

const headerStyle = {
  padding: 5, background: Colors.info.background,
  margin: 0,
  width: '100%',
  height: '70px',
};

const contentStyle = {
  overflow: 'scroll',
  //textAlign: 'center',
  //minHeight: 400,
  color: '#000',
  backgroundColor: Colors.light.background,
  height: 'calc(100vh - 70px - 20px)',
};

const siderLeftStyle = {
  overflow: 'scroll',
  color: '#000',
  backgroundColor: Colors.secondary.background,
  height: 'calc(100vh - 70px - 20px)',
  width: '15%',
  zIndex: 2
};

const siderRightStyle = {
  color: '#000',
  backgroundColor: Colors.info.background,
  height: 'calc(100vh - 70px - 20px)',
  overflow: 'scroll',
};

const footerStyle = {
  textAlign: 'center',
  height: '20px',
  backgroundColor: Colors.info.background,
  margin: 0,
  padding: 0
};

const layoutStyle = {
  height: '100%',
  zIndex: 2
};

export { FACTOR, contentStyle, footerStyle, headerStyle, layoutStyle, siderRightStyle, siderLeftStyle};