import React, { useCallback, useContext, useEffect, useState, useRef, useMemo } from "react";
import { Affix, Card, Flex, Input, Modal, Button, Layout, Dropdown, Result, Space, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import PropTypes from 'prop-types';

import { getColumnSearchProps, getSearch } from "./Utils";

const DatatableMongo = ({ columns, collection, keyField = '_id', model, pageSize = 10, sort = { _id: 1 }, sourceFilter = {}, token, ...other }) => {
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: pageSize,
      showTotal: (total) => `Total ${total} registros`
    },
    filters: {},
    reload: 0
  })
  const [localColumns, setLocalColumns] = useState([]);
  const [data, setData] = useState([]);
  const [filterMongo, setFilterMongo] = useState({
    ...sourceFilter
  });

  useEffect(() => {
    async function rebuildCols() {
      let cols = [
        ...columns
      ]

      for (let i = 0; i < cols.length; i++) {
        let col = cols[i];
        if (col.autoFilter) {
          let opts = await model.getDistinct({
            collection,
            field: col.dataIndex,
            filter: sourceFilter,
            token: token
          });
          col.filters = opts.map(o => ({
            text: o,
            value: o
          }))
        }
        else if (col.search) {
          cols[i] = {
            ...col,
            ...getColumnSearchProps(col.dataIndex)
          }
        }
      }

      setLocalColumns(cols);
    }

    rebuildCols();

  }, [columns])

  useEffect(() => {
    let flt = {
      ...sourceFilter,
    }

    for (let key in tableParams.filters) {
      let col = columns.find(c => c.dataIndex === key);

      if (tableParams.filters[key] != null) {
        if (col && col.search) {
          flt[key] = getSearch(tableParams.filters[key], col)
        }
        else {
          if (Array.isArray(tableParams.filters[key])) {
            flt[key] = { $in: tableParams.filters[key] }
          }
          else {
            flt[key] = tableParams.filters[key]
          }
        }
      }
    }
    console.log('filterMongo', flt)
    setFilterMongo(flt);
  }, [JSON.stringify(sourceFilter), JSON.stringify(tableParams.filters)])


  useEffect(() => {
    async function fetchTotal() {
      let dt = await model.getCount({
        collection,
        filter: filterMongo,
        token: token
      })
      setTableParams(prev => ({
        ...prev,
        pagination: {
          current: 1,
          pageSize: prev.pagination.pageSize,
          total: dt.count,
          showTotal: (total) => `Total ${total} registros`
        },
        reload: prev.reload + 1
      }))
    }

    fetchTotal()
  }, [JSON.stringify(filterMongo)])


  useEffect(() => {
    async function fetchData() {
      console.log('fetch')
      let skip = (tableParams.pagination.current - 1) * (tableParams.pagination.pageSize);
      let sortOrder = tableParams.sortField ? {
        [tableParams.sortField]: tableParams.sortOrder
      } : sort;

      let dt = await model.getList({
        collection,
        filter: filterMongo,
        skip,
        limit: tableParams.pagination.pageSize,
        token: token,
        sort: sortOrder
      })
      setData(dt);
    }

    if (tableParams?.pagination?.total && tableParams?.pagination?.current) {
      fetchData()
    }
    else if (tableParams?.pagination?.total === 0) {
      setData([])
    }

  }, [tableParams.pagination.total, tableParams.pagination.current, tableParams.pagination.pageSize,
  tableParams.sortField, tableParams.sortOrder, tableParams.reload])


  const onChangeTable = (pagination, filters, sorter) => {
    console.log('filters', filters)
    console.log('pagination', pagination)
    if (JSON.stringify(filters) !== JSON.stringify(tableParams.filters)) {
      console.log('Cambiando filtros', filters)
      setTableParams(prev => ({
        ...prev,
        filters
      }))
    }
    else {
      setTableParams(prev => ({
        ...prev,
        pagination: {
          ...prev.pagination,
          ...pagination
        },
        sortOrder: Array.isArray(sorter) ? undefined : (sorter.order === 'ascend' ? 1 : -1),
        sortField: Array.isArray(sorter) ? undefined : sorter.field,
      }))
    }

  }

  return <Table
    {...other}
    columns={localColumns}
    rowKey={(record) => record[keyField]}
    dataSource={data}
    pagination={tableParams?.pagination}
    onChange={onChangeTable}
  />
}

DatatableMongo.propTypes = {
  collection: PropTypes.string,
  columns: PropTypes.array,
  keyField: PropTypes.string,
  model: PropTypes.object,
  pageSize: PropTypes.number,
  sort: PropTypes.object,
  sourceFilter: PropTypes.object,
  token: PropTypes.string
};

export { DatatableMongo as default };