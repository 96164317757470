import React, { useState, useEffect } from "react";
import useSite from './useSite';
import Utils from '../others/Utils';
import { saveAs } from 'file-saver';
import { modalGlobalConfig } from "antd/es/modal/confirm";
//import { withSnackbar } from 'notistack';

const useMongo = ({ columns: propColumns = [], components: propComponents = [], database, collection: modelCollection }) => {
  const [_register, setRegister] = useState({})
  const { axios, api, authHeader } = useSite({
    site: 'production'
  })

  const model = {};
  const components = {};
  const columns = {};

  for (let column of propColumns) {
    columns[column.id || column.name] = column;
  }

  for (let component of propComponents) {
    components[component.id || component.name] = component.component;
  }

  model.create = ({ collection, data, token }) => {
    if (!token) {
      return Promise.reject({
        message: 'Not logged',
        color: 'warning',
        time: 1000
      });
    }
    if( !Array.isArray(data) ) {
      if (data._id) {
        delete data._id;
      }
      let d = Utils.deepClone(data);
      Utils.cleanData(d);
    }
    
    let url = `${api}${collection || modelCollection}`;
    return axios({
      method: 'post',
      url: url,
      data: data,
      responseType: 'json',
      headers: {
        [authHeader]: `Bearer ${token}`,
        'x-database': database
      }
    });
  }

  model.get = ({ _id, collection, projection = {}, token }) => {
    if (!token) {
      return Promise.reject({
        message: 'Not logged',
        color: 'warning',
        time: 1000
      });
    }

    let params = {
      projection
    };

    let url = `${api}${collection || modelCollection}/${_id}?${Utils.buildParameters(params)}`;

    return axios.get(url, {
      headers: {
        [authHeader]: `Bearer ${token}`,
        'x-database': database
      }
    }).then(function (result) {
      return Promise.resolve(result.data);
    });

  };

  model.getRegister = ({ _id, collection, projection = {}, token }) => {
    if (!token) {
      return Promise.reject({
        message: 'Not logged',
        color: 'warning',
        time: 1000
      });
    }

    let params = {
      projection
    };
    let url = `${api}${collection || modelCollection}/${_id}?${Utils.buildParameters(params)}`;

    return axios.get(url, {
      headers: {
        [authHeader]: `Bearer ${token}`,
        'x-database': database
      }
    }).then(function (result) {
      Utils.cleanData(result.data)
      setRegister(Utils.deepClone(result.data));
      return Promise.resolve(result.data);
    });
  };

  model.getActualRegister = () => {
    return _register
  }

  model.getList = ({ collection, filter, limit, projection, skip, sort, token }) => {
    if (!token) {
      return Promise.reject({
        message: 'Not logged',
        color: 'warning',
        time: 1000
      });
    }

    let params = {
      filter, limit, projection, skip, sort
    }

    Utils.cleanData(params);

    let url = `${api}${collection || modelCollection}?${Utils.buildParameters(params)}`;

    return axios({
      method: 'get',
      url: url,
      responseType: 'json',
      headers: {
        [authHeader]: `Bearer ${token}`,
        'x-database': database
      }
    }).then(function (result) {
      return Promise.resolve(result.data);
    });
  };


  model.getCount = ({ collection, filter, token }) => {
    if (!token) {
      return Promise.reject({
        message: 'Not logged',
        color: 'warning',
        time: 1000
      });
    }

    let params = {
      filter
    }

    let url = `${api}${collection || modelCollection}/_count?${Utils.buildParameters(params)}`;

    return axios({
      method: 'get',
      url: url,
      responseType: 'json',
      headers: {
        [authHeader]: `Bearer ${token}`,
        'x-database': database
      }
    }).then(function (result) {
      return Promise.resolve(result.data);
    });
  };

  model.getDistinct = ({ collection, field, filter, token }) => {
    if (!token) {
      return Promise.reject({
        message: 'Not logged',
        color: 'warning',
        time: 1000
      });
    }

    let params = {
      field,
      filter
    }

    let url = `${api}${collection || modelCollection}/_distinct?${Utils.buildParameters(params)}`;

    return axios({
      method: 'get',
      url: url,
      responseType: 'json',
      headers: {
        [authHeader]: `Bearer ${token}`,
        'x-database': database
      }
    }).then(function (result) {
      return Promise.resolve(result.data);
    });
  };

  model.updateRegister = ({ command = '$set', collection, register, token }) => {
    if (!token) {
      return Promise.reject({
        message: 'Not logged',
        color: 'warning',
        time: 1000
      });
    }
    let _id = _register._id;
    if (!_id) {
      return Promise.reject({
        message: 'Not Id',
        color: 'warning',
        time: 1000
      });
    }
    let diffs = Utils.getDiffs(_register, register)
    if (diffs._id) {
      return Promise.reject({
        message: "Id can't be changed",
        color: 'warning',
        time: 1000
      });
    }

    let params = {
      command
    };

    let url = `${api}${collection || modelCollection}/${_id}?${Utils.buildParameters(params)}`;

    return axios({
      method: 'patch',
      url: url,
      data: diffs,
      responseType: 'json',
      headers: {
        [authHeader]: `Bearer ${token}`,
        'x-database': database
      }
    });
  };

  model.updateOne = ({ _id, command = '$set', collection, diffs, token }) => {
    if (!token) {
      return Promise.reject({
        message: 'Not logged',
        color: 'warning',
        time: 1000
      });
    }
    if (!_id) {
      return Promise.reject({
        message: 'Not Id',
        color: 'warning',
        time: 1000
      });
    }

    let params = {
      command
    };

    if (diffs._id) {
      if (diffs._id !== _id) {
        return Promise.reject({
          message: "Id can't be changed",
          color: 'warning',
          time: 1000
        });
      }
      delete diffs._id;
    }

    let url = `${api}${collection || modelCollection}/${_id}?${Utils.buildParameters(params)}`;

    return axios({
      method: 'patch',
      url: url,
      data: diffs,
      responseType: 'json',
      headers: {
        [authHeader]: `Bearer ${token}`,
        'x-database': database
      }
    });
  };

  model.updateMany = ({ command = '$set', collection, diffs, filter = {}, token }) => {
    if (!token) {
      return Promise.reject({
        message: 'NotLogged',
        color: 'warning',
        time: 1000
      });
    }

    let params = {
      filter,
      command
    };

    let url = `${api}${collection || modelCollection}?${Utils.buildParameters(params)}`;

    return axios({
      method: 'patch',
      url: url,
      data: diffs,
      responseType: 'json',
      headers: {
        [authHeader]: `Bearer ${token}`,
        'x-database': database
      }
    });
  };

  return { model, components, columns, axios, api, authHeader };
};

export default useMongo;