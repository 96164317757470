import React from 'react';
import useMongo from './useMongo';

const useVwFeatSignals = (token) => {

  const { model, columns, components, api, axios, authHeader } = useMongo({
    database: 'chg',
    collection: 'vw_features_with_signals',
    columns: [],
    components: []
  });

  return { model, columns, components, api }
}

export default useVwFeatSignals;