import React, { useContext, useEffect, useMemo, useState } from "react";
import { Col, Typography, Button, Row, Modal, Form, Input, Select } from "antd";
import { UserContext } from '../others/UserContext';
import useSignals, { typeOptions } from '../hooks/useSignals';
import RegisterForm from "../components/RegisterForm.js";
import { useLocation, useParams, Link } from "react-router-dom";
import useData from '../hooks/useData.js';
import useFeatures from '../hooks/useFeatures';
import { Standard, Sidebar } from "../layouts";
import { useSnackbar } from "notistack";
import { DateTime } from "luxon";
import Datatable from "../components/Datatable.js";
import Colors from '../colors.json';
import '../others/proj4-module'
import Utils from "../others/Utils.js";


function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

const ViewFeature = () => {
  const context = useContext(UserContext);
  const [register, setRegister] = useState({});
  const [signals, setSignals] = useState([]);
  const [otherSignals, setOtherSignals] = useState([]);
  const [lastData, setLastdata] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [searching, setSearching] = useState(false);
  const [modalSignal, setModalSignal] = useState({})
  const [modalExistingSignal, setModalExistingSignal] = useState({})
  const { enqueueSnackbar } = useSnackbar();
  const { model: modelSignals, columns: colSignals } = useSignals(context.token);
  const { model: modelData } = useData(context.token);
  const { model: modelFeatures, columns: colFeatures, api, axios, authHeader } = useFeatures(context.token);

  const { Title } = Typography;

  const { id: idFeature } = useParams();
  let query = useQuery();

  const columnsSignals = useMemo(() => {
    return [
      ...Object.values(colSignals),
      {
        title: 'Acción',
        dataIndex: '_id',
        render: (v, row) => {
          return <><Link
            to={`/signal/${v}`}>
            <Button>Edita</Button>
          </Link>
          </>
        }
      },
    ]
  }, [colSignals])

  const columnAffectingFeatures = [
    {
      id: 'value',
      title: 'Elemento',
      dataIndex: 'value',
    },
  ]
  const columnAffectingSignals = [
    {
      id: 'value',
      title: 'Señal',
      dataIndex: 'value',
    },
  ]

  const localize = async () => {
    if (!Array.isArray(register.point)) {
      return;
    }
    try {
      window.proj4.defs("EPSG:32630", "+proj=utm +zone=30 +datum=WGS84 +units=m +no_defs +type=crs");

      let latlon = window.proj4("EPSG:32630").inverse(register.point);

      let resp = await axios.get(`${api}_findSubbasin?x=${register.point[0]}&y=${register.point[1]}`, {
        responseType: 'json',
        headers: { Authorization: `Bearer ${context.token}` }
      });
      //console.log('latlon',latlon)
      let subbasin_id = resp.data.subbasin_id
      await modelFeatures.updateOne({
        _id: register._id,
        diffs: {
          latlon,
          subbasin_id
        },
        token: context.token
      })

      enqueueSnackbar(`Latlon ${Utils.latlonToString(latlon)} y Subcuenca ${subbasin_id}`, { variant: 'success' })
    }
    catch (err) {
      enqueueSnackbar(`Error: ${err.message}`, { variant: 'danger' })
    }
  }

  const saveRegister = async () => {
    try {
      await modelFeatures.updateOne({
        _id: register._id,
        diffs: register,
        token: context.token
      })
    }
    catch (err) {
      enqueueSnackbar(`Error: ${err.message}`, { variant: 'danger' })
    }
  }

  const getLatestData = async () => {
    enqueueSnackbar('Buscando datos...', { variant: 'info' })
    setSearching(true);

    try {
      let fromDate = lastData ? DateTime.fromISO(lastData).plus({ days: 1 })
      : DateTime.fromISO('2000-01-01');
      let changes = await modelFeatures.addSignalsData({
        feature: register,
        fromDate: fromDate.toISO(),
        token: context.token,
        modelSignals
      })

      setLoaded(false);
      enqueueSnackbar(changes.data.message, { variant: changes.data.error ? 'error' : 'success' })
    }
    catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' })
    }
    finally {
      setSearching(false)
    }

  };

  useEffect(() => {
    async function fetchData() {
      
      console.log('fetchData')
      let regFeature = {};
      if( idFeature ) {
        regFeature = await modelFeatures.getRegister({
          _id: idFeature,
          token: context.token
        });
      }
      else {
        let feature_id = query.get("id");
        let regs = await modelFeatures.getList({
          filter: {
            id: feature_id
          },
          token: context.token
        });
        if( regs.length>0 ) {
          regFeature = regs[0]
        }
      }

      let otherSgnls = await modelSignals.getList({
        filter: {
          featureId: { "$ne": regFeature.id }
        },
        projection: { 
          "_id": 0,
          "id": 1,
          "featureId": 1,
          "name": 1,
          "type": 1
        },
        token: context.token
      })

      let sgnls = await modelSignals.getList({
        filter: {
          featureId: regFeature.id
        },
        token: context.token
      })

      let last = false;
      
      for (let reg of sgnls) {
        let flt = {}
        if (!Array.isArray(reg.signals)) {
          reg.signals = []
        }
        if (reg.signals.length === 1) {
          flt = JSON.parse(reg.signals[0].filter);
        }
        else if (reg.signals.length > 1) {
          let subflsts = []
          for (let s of reg.signals) {
            let sf = JSON.parse(s.filter);
            if (s.fromDate && s.toDate) {
              sf['$and'] = [
                { [reg.dateField]: { $gte: s.fromDate } },
                { [reg.dateField]: { $lte: s.toDate } }
              ]
            }
            else if (s.fromDate) {
              sf[reg.dateField] = { $gte: s.fromDate }
            }
            else if (s.toDate) {
              sf[reg.dateField] = { $lte: s.toDate }
            }
            subflsts.push(sf);
          }
          flt = { $or: subflsts };
        }

        let reg_latest = await modelData.getList({
          collection: reg.collection,
          limit: 1,
          filter: flt,
          sort: { [reg.dateField]: -1 },
          projection: {
            [reg.dateField]: 1
          },
          token: context.token
        })

        if (reg_latest.length > 0 && (!last || last < reg_latest[0][reg.dateField])) {
          last = reg_latest[0][reg.dateField]
        }
      }


      otherSgnls.forEach( s => {
        s.value = s.id;
        let tp = typeOptions.find( t => t.value === s.type)
        s.label = `${s.id} en ${s.featureId}: ${s.name} [${tp ? tp.label : s.type}]`
      } )

      console.log('otherSgnls', otherSgnls)
      console.log('sgnls', sgnls)

      setLastdata(last);
      setSignals(sgnls);
      setOtherSignals(otherSgnls);
      setRegister(regFeature);
      setLoaded(true);
    }

    if (!loaded) {
      fetchData();
    }
  }, [idFeature, loaded])

  const makeBase = async () => {
    let url = `${api}_makeBase/${register.id}?email=${context.email}`;
    console.log('url', url)
    let response = await axios({
      method: 'post',
      url: url,
      responseType: 'json',
      headers: {
        [authHeader]: `Bearer ${context.token}`,
        'x-database': 'chg'
      }
    })
    console.log('response', response)
    enqueueSnackbar(response.data.message, { variant: 'info' })
  }

  return <Standard
    header={<Title>Elemento {register.id} {register.featureName}</Title>}
    headerRight={<>
      {lastData && <Title level={3}>Últimos datos el {lastData}</Title>}
      <Button
        type='primary'
        onClick={() => {
          setModalSignal(prev => ({
            ...prev,
            show: true
          }))
        }}
        style={{ marginLeft: '1em', marginTop: '1.2em', marginRight: '1em', backgroundColor: Colors.warning.backgroundColor }}>
        Añade señal
      </Button>
      <Button
        type='primary'
        onClick={saveRegister}
        style={{ marginLeft: '1em', marginTop: '1.2em', marginRight: '1em', backgroundColor: Colors.warning.backgroundColor }}>
        Graba
      </Button>
      <Button
        type='primary'
        onClick={localize}
        style={{ marginLeft: '1em', marginTop: '1.2em', marginRight: '1em', backgroundColor: Colors.warning.backgroundColor }}>
        Localiza
      </Button>
      <Button
        disabled={searching}
        type='primary'
        onClick={getLatestData}
        style={{ marginLeft: '1em', marginTop: '1.2em', marginRight: '1em', backgroundColor: Colors.warning.backgroundColor }}>
        Actualiza datos
      </Button>
      <Button
        type='primary'
        onClick={makeBase}
        style={{ marginLeft: '1em', marginTop: '1.2em', marginRight: '1em', backgroundColor: Colors.warning.backgroundColor }}
      >Genera Base</Button>
    </>}
    left={<Sidebar routes={context.routes.filter(r => r.bigIcon)} />}
  >
    {
      loaded &&
      <>
        <Row style={{ width: '100%' }}><Col style={{ width: '100%' }}><RegisterForm
          fields={colFeatures}
          register={register}
          onChange={({ name, value }) => {
            setRegister(prev => ({
              ...prev,
              [name]: value
            }))
          }} /></Col></Row>
        <Row style={{ marginTop: '1em' }}><Col style={{ width: '100%' }}><Title level={2}>Señales</Title></Col></Row>
        <Row>
          <Col style={{ width: '100%' }}>
            <Datatable
              columns={columnsSignals}
              dataSource={signals}
            />
          </Col>
        </Row>
        <Row>
          <Col style={{ width: '50%' }}>
            <Row style={{ marginTop: '1em' }}><Col style={{ width: '100%' }}><Title level={2}>Elementos que lo afectan</Title></Col></Row>
            <Row>
              <Col style={{ width: '100%' }}>
                <Datatable
                  columns={columnAffectingFeatures}
                  dataSource={register?.affectingFeatures || []}
                />
              </Col>
            </Row>
          </Col>
          <Col style={{ width: '50%' }}>
            <Row style={{ marginTop: '1em' }}><Col style={{ width: '80%' }}><Title level={2}>Señales que lo afectan</Title></Col>
            <Col>
            <Button
              type='primary'
              onClick={() => {
                setModalExistingSignal(prev => ({
                  ...prev,
                  show: true
                }))
              }}
              style={{ marginLeft: '1em', marginTop: '1.2em', marginRight: '1em', backgroundColor: Colors.warning.backgroundColor }}>
              Añade señal que afecta
            </Button>
            </Col></Row>
            <Row>
              <Col style={{ width: '100%' }}>
                <Datatable
                  columns={columnAffectingSignals}
                  dataSource={register?.affectingSignals || []}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Modal
          title="Nueva señal"
          width={800}
          open={modalSignal.show}
          onOk={async () => {
            let data = {
              ...modalSignal,
              featureId: register.id
            }
            switch (register.featureClass) {
              default:
                break;
              case 'ifapa':
                data.signals = [{ field: modalSignal.field, filter: `{"station_id":"${register.id}"}` }]
                break;
              case 'saih':
                data.signals = [{ field: 'value', filter: `{"name":"${modalSignal.id}"}` }]
                break;
              case 'piezometer':
                data.signals = [{ field: 'depth', filter: `{"piezo_id":"${modalSignal.id}"}` }]
                break;
            }
            delete data.show;
            delete data.field;
            await modelSignals.create({
              data,
              token: context.token
            });
            setModalSignal(prev => ({
              ...prev,
              show: false,
              active: true
            }));
            setLoaded(false);
          }}
          onCancel={() => {
            setModalSignal(prev => ({
              ...prev,
              show: false
            }))
          }}>
          <Row>
            <RegisterForm
              style={{ width: '100%' }}
              fields={colSignals}
              noDisableds
              noExternals
              register={modalSignal}
              onChange={({ name, value }) => {
                setModalSignal(prev => ({
                  ...prev,
                  [name]: value
                }))
              }} />
          </Row>
          {register.featureClass === 'ifapa' &&
            <Row>
              <Col>
                <Form.Item layout="vertical"
                  label={<b>Nombre del campo</b>}
                >
                  <Input
                    value={modalSignal.field}
                    onChange={(v) => {
                      setModalSignal(
                        prev => ({
                          ...prev,
                          field: v.target.value
                        }))
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          }
        </Modal>
        <Modal
          title="Añade señal a afecciones"
          width={600}
          open={modalExistingSignal.show}
          onOk={async () => {
            debugger;
            let affs = [...(register.affectingFeatures || []) ]
            let sgs = [...(register.affectingSignals || []) ]
            if( affs.indexOf(modalExistingSignal.featureId)===-1) {
              affs.push(modalExistingSignal.featureId)
            }
            if( sgs.indexOf(modalExistingSignal.signalId)===-1) {
              sgs.push(modalExistingSignal.signalId)
            }
            let reg = {
              ...register,
              affectingFeatures: affs,
              affectingSignals: sgs
            }
            setRegister(reg);
            
            setModalExistingSignal(prev => ({
              ...prev,
              show: false
            }));
          }}
          onCancel={() => {
            setModalExistingSignal(prev => ({
              ...prev,
              show: false
            }))
          }}>
          <Row>
            <Col>
            <Select
                style={{ width: 400 }}
                options={otherSignals}
                value={modalExistingSignal.signalId}
                onChange={(v) => {
                  let opt = otherSignals.find(o => o.value == v);
                  setModalExistingSignal(prev => ({
                    ...prev,
                    signalId: v,
                    featureId: opt.featureId
                  }))
                }}
              />
            </Col>
          </Row>
        </Modal>
      </>
    }
  </Standard>
};

export default ViewFeature;
