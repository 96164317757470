import React, { useState, useEffect, useMemo } from "react";
import {
  Button, Col, ColorPicker, Dropdown, Form, Input, InputNumber, Modal, Popconfirm,
  Radio, Row, Select, Slider, Switch, Upload
} from "antd";
import { BorderOutlined, XFilled } from "@ant-design/icons";
import Colors from './canvasColors.json';
import Datatable from "./Datatable";

const inForm = (type) => {
  if( type == 'table') return false;
  return true;
}

const fieldTypes = {
  'options': ({ name, value, label, options, onChange, render, ...other }) => {
    return <Select
        {...other}
        options={options}
        value={value}
        onChange={(v) => { onChange({ name, value: v }) }}
      />
  },
  'string': ({ name, value, label, multiline, onChange, render, ...other }) => {
    return <>
      {
        multiline &&
        <Input.TextArea
          {...other}
          value={render ? render(value) : value}
          onChange={(v) => {
            onChange({ name, value: v.target.value })
          }}
        />
      }
      {
        !multiline &&
        <Input
          {...other}
          value={render ? render(value) : value}
          onChange={(v) => { onChange({ name, value: v.target.value }) }}
        />
      }
    </>
  },
  'number': ({ name, value, label, onChange, render, ...other }) => {
    return <InputNumber
          {...other}
          value={render ? render(value) : value}
          onChange={(v) => { 
            onChange({ name, value: v}) }}
        />

  },
  'point': ({ name, value, label, onChange, render, ...other }) => {
    return <div style={{display:'flex',flexDirection:'row'}}>      
        <Input
          {...other}
          value={Array.isArray(value) ? value[0] : 0}
          onChange={(v) => { 
            let p = [ parseFloat(v.target.value), (Array.isArray(value) ? value[1] : 0) ]
            onChange({ name, value: p }) }}
        />
         <Input
          {...other}
          value={Array.isArray(value) ? value[1] : 0}
          onChange={(v) => { 
            let p = [ (Array.isArray(value) ? value[0] : 0), parseFloat(v.target.value) ]
            onChange({ name, value: p }) }}
        />
    </div>
  },
  'color': ({ disabled, name, value, label, onChange, render, ...other }) => {
    return <div style={{float:'right'}}>
      <Dropdown.Button
        title='Color'
        disabled={disabled}
        menu={{
          onClick: (e) => {
            let color = Colors[e.key].background
            onChange({ name, value: color })
          },
          items: Object.keys(Colors).map(key => ({
            key: key,
            icon: Colors[key].background === '#FFFFFF' ?
              <BorderOutlined />
              : <XFilled style={{ color: Colors[key].background }} />
          }))
        }}
      ><ColorPicker
        value={value}
        disabled={disabled}
        onChange={(v) => { onChange({ name, value: v.toHexString() }) }}
      />
      </Dropdown.Button>
    </div>
  },
  'boolean': ({ name, value, label, onChange, render, ...other }) => {
    return <Switch
          {...other}
          value={value}
          onChange={(v) => { onChange({ name, value: v }) }}
        />
  },
  'slider': ({ name, value, label, min, max, step, onChange, render, ...other }) => {
    return <Slider
        style={{
          width: '8vw'
        }}
        {...other}
        value={value}
        min={min}
        max={max}
        step={step}
        onChange={(v) => { onChange({ name, value: v }) }}
      />
  },
  'table': ({columns, key, value}) => {
    return <Row key={key}><Col style={{width:'100%'}}><Datatable
      columns={columns}
      dataSource={value}
    /></Col></Row>
  } //http://127.0.0.1:43110/18D6dPcsjLrjg2hhnYqKzNh2W6QtXrDwF/

}

function isCentered(field) {
  return field.center === undefined ? (field.type==='boolean' ? true : false) : field.center 
}

function RegisterForm({fields, noDisableds, noExternals, onChange, register}) {
  return <><Form className="stretchForm"><Row gutter={[8,8]}>
    {
      Object.values(fields).filter( f => (!noDisableds || !f.disabled ) && f.type && (f.type in fieldTypes) && 
        inForm(f.type)).map( ({autoFilter, center, dataIndex, disabled, filterSearch, 
        id, integer, render, search, sorter, span, title, type, ...other}) => {
        return <Col key={`F-${id || dataIndex}`} style={{height:'fit-content'}} span={span || 8}>
          <Form.Item layout="vertical" 
            label={<b>{title}</b>}
            className={isCentered({center, type}) ? 'text-center' : ''}>
          {
            fieldTypes[type]({
              ...other,
              name: dataIndex,
              label: title,
              disabled: disabled,
              value: register[dataIndex],
              render,
              onChange
            })
          }
        </Form.Item></Col>
        }
      )
    }
    </Row>
    </Form>
    { !noExternals && 
      Object.values(fields).filter( f => (!noDisableds || !f.disabled ) && f.type && (f.type in fieldTypes) && !inForm(f.type)).map( ({autoFilter, center, dataIndex, disabled, filterSearch, 
        id, integer, render, search, sorter, span, title, type, ...other}) => {
          return fieldTypes[type]({
            ...other,
            key: `F-${id}`,
            name: dataIndex,
            label: title,
            disabled: disabled,
            value: register[dataIndex],
            render,
            onChange
          })
        })
    }
    </>
}

export default RegisterForm;