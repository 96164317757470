import React, { useContext, useMemo, useState, useEffect } from "react";
import { Typography, Modal, Button, Row } from "antd";
import { UserContext } from '../others/UserContext';
import DatatableMongo from "../components/DatatableMongo.js";
import { filterOptions, latlonToString, renderOptions, renderTrueFalse, yesNoOptions } from '../components/Utils';
import useModels from '../hooks/useModels';
import RegisterForm from "../components/RegisterForm.js";
import { useLocation, Link } from "react-router-dom";
import { Standard, Sidebar } from "../layouts";
import useSignals, { typeOptions } from '../hooks/useSignals.js';
import { featureTypeOptions } from '../hooks/useFeatures.js';
import { useSnackbar } from "notistack";
import Colors from '../colors.json';

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

const defaultModalValue = {
  add_feature_types: ['*.rain', 'dam.flow'],
  threshold_column: 0.5,
  demo: false,
  from_days: 30,
  to_days: 30,
  nickname: '',
  limit: 0,
  minimum: 50,
  nlargest: 8,
  output: false,
  quarters: false,
  threshold_row: 0.5,
  strategy: 'knn',
  unrepeat: false,
  savelimit: 0.5,
  width: 6,
  regex: false,
  height: 15,
  testsize: 0.2
}

const ViewModels = () => {
  const [modalModel, setModalModel] = useState(defaultModalValue)
  const [signals, setSignals] = useState([])
  const context = useContext(UserContext);
  const { model: modelModels, columns: colModels, api, axios, authHeader } = useModels(context.token);
  const { model: modelSignals } = useSignals(context.token);
  const { enqueueSnackbar } = useSnackbar();

  let query = useQuery();

  useEffect( () => {
    async function fetchSignals() {

      let signal_id = query.get("signal_id"); 
      let filter = {};
      if( signal_id ) {
        filter = {
          id: signal_id
        }
        setModalModel(prev => ({
          ...prev,
          signal_id
        }))
      }

      let sgns = await modelSignals.getList({
        filter,
        projection: {_id:0, id:1, name:1},
        token: context.token
      })

      setSignals( sgns.map(s => ({
        label: `${s.id} ${s.name}`,
        value: s.id
      })))
    }

    fetchSignals();
  }, [])

  const sourceFilters = useMemo(() => {
    let signal_id = query.get("signal_id");
    if (signal_id) {
      return {
        signal_id
      }
    }
    return {}
  }, [])

  const columns = useMemo(() => (
    [
      ...Object.values(colModels),
      {
        title: 'Acción',
        dataIndex: 'signal_id',
        render: (v, row) => {
          return <><Link
            to={`/signal?id=${v}`}>
            <Button>Señal</Button>
          </Link>
          </>
        }
      },
    ]), [])

  const colsModal = useMemo(() => {
    if( signals.length == 0) {
      return [];
    }

    let opts = [];

    for (let t of typeOptions) {
      opts.push({
        label: `*.${t.label}`,
        value: `*.${t.value}`,
      })
    }
    for (let ft of featureTypeOptions) {
      opts.push({
        label: `${ft.label}.*`,
        value: `${ft.value}.*`,
      })
      for (let t of typeOptions) {
        opts.push({
          label: `${ft.label}.${t.label}`,
          value: `${ft.value}.${t.value}`,
        })
      }
    }

    return [
      {
        title: 'Id señal',
        dataIndex: 'signal_id',
        type: 'options',
        options: signals,
        span: 8
      },
      {
        title: 'Regex',
        dataIndex: 'regex',
        id: 'regex',
        type: 'boolean',
        span: 4
      },
      {
        title: 'Demo',
        dataIndex: 'demo',
        type: 'boolean',
        span: 4
      },
      {
        title: 'Output',
        dataIndex: 'output',
        type: 'boolean',
        span: 4
      },
      {
        title: 'Trimestres',
        dataIndex: 'quarters',
        type: 'boolean',
        span: 4
      },
      {
        title: 'Desde días',
        dataIndex: 'from_days',
        type: 'number',
        span: 4,
        step: 1,
      },
      {
        title: 'Hasta días',
        dataIndex: 'to_days',
        type: 'number',
        span: 4,
        step: 1,
      },
      {
        title: 'Señales',
        dataIndex: 'add_feature_types',
        type: 'options',
        options: opts,
        mode: "multiple",
        allowClear: true,
        span: 16
      },
      {
        title: 'Sufijo',
        dataIndex: 'nickname',
        type: 'string',
        span: 4
      },
      {
        title: 'Límite',
        dataIndex: 'limit',
        type: 'number',
        span: 4,
        step: 0.5
      },
      {
        title: 'Mínimas filas',
        dataIndex: 'minimum',
        type: 'number',
        span: 4,
        step: 1,
      },
      {
        title: 'Estrategia',
        dataIndex: 'strategy',
        type: 'options',
        options: [
          {
            label: 'knn',
            value: 'knn'
          },
          {
            label: 'mean',
            value: 'mean'
          },
          {
            label: 'median',
            value: 'median'
          },
          {
            label: 'zero',
            value: 'zero'
          }],
        span: 4
      },
      {
        title: 'Lím Fila',
        dataIndex: 'threshold_row',
        type: 'number',
        span: 4,
        step: 0.1,
      },
      {
        title: 'Lím Columna',
        dataIndex: 'threshold_column',
        type: 'number',
        span: 4,
        step: 0.1,
      },
      {
        title: 'Tamaño test',
        dataIndex: 'testsize',
        type: 'number',
        span: 4,
        step: 0.05,
      },
      {
        title: 'Lím grabación',
        dataIndex: 'savelimit',
        type: 'number',
        span: 4,
        step: 0.05,
      },
      {
        title: 'Característicos',
        dataIndex: 'nlargest',
        type: 'number',
        span: 4,
        step: 1,
      },
      {
        title: 'Anchura',
        dataIndex: 'width',
        type: 'number',
        span: 4,
        step: 1,
      },
      {
        title: 'Altura',
        dataIndex: 'height',
        type: 'number',
        span: 4,
        step: 1,
      },
      {
        title: 'Sin repetir',
        dataIndex: 'unrepeat',
        type: 'boolean',
        span: 4
      },
    ]
  }, [signals])


  const { Title } = Typography;

  return <Standard
    header={<Title>Modelos</Title>}
    headerRight={
      <Button
        type='primary'
        onClick={() => {
          setModalModel(prev => ({
            ...prev,
            show: true
          }))
        }}
        style={{ marginLeft: '1em', marginTop: '1.2em', marginRight: '1em', backgroundColor: Colors.warning.backgroundColor }}>
        Añade modelo
      </Button>}
    left={<Sidebar routes={context.routes.filter(r => r.bigIcon)} />}
  >
    <DatatableMongo
      sourceFilter={sourceFilters}
      columns={columns}
      model={modelModels}
      keyField='_id'
      token={context.token}
    />
    <Modal
      title="Nuevo modelo"
      width={800}
      open={modalModel.show}
      onOk={async () => {
        try {
        let params = [`email=${encodeURIComponent(context.email)}`]
        debugger;
        for (let key in modalModel) {
          if (key === 'show' || key === 'signal_id') continue;
          if (Array.isArray(modalModel[key])) {
            if (JSON.stringify(modalModel[key]) !== JSON.stringify(defaultModalValue[key])) {
              params.push(`${key}=${encodeURIComponent(modalModel[key].join(','))}`)
            }
          }
          else if (modalModel[key] !== defaultModalValue[key]) {
            if (typeof modalModel[key] === 'boolean') {
              params.push(`${key}=${(modalModel[key] ? 'true' : 'false')}`)
            }
            else {
              params.push(`${key}=${encodeURIComponent(modalModel[key])}`)
            }
          }
        }
        let url = `${api}_makeModels/${modalModel.signal_id}?${params.join('&')}`;
        console.log('url', url)
        let resp = await axios({
          method: 'post',
          url: url,
          responseType: 'json',
          headers: {
            [authHeader]: `Bearer ${context.token}`
          }
        });
        
        setModalModel(prev => ({
          ...prev,
          show: false
        }))
        enqueueSnackbar( resp.data.message, { variant: 'success' })
    }
    catch (err) {
      enqueueSnackbar(`ERROR: ${err.message}`, { variant: 'error' })
    }
      }}
      onCancel={() => {
        setModalModel(prev => ({
          ...prev,
          show: false
        }))
      }}>
      <Row>
        <RegisterForm
          style={{ width: '100%' }}
          fields={colsModal}
          noDisableds
          noExternals
          register={modalModel}
          onChange={({ name, value }) => {
            setModalModel(prev => ({
              ...prev,
              [name]: value
            }))
          }} />
      </Row>
    </Modal>
  </Standard>
};

export default ViewModels;
