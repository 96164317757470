import React, { useContext, useEffect, useState } from "react";
import { Typography  } from "antd";
import { UserContext } from '../others/UserContext';
import DatatableMongo from "../components/DatatableMongo.js";
import useSignals from '../hooks/useSignals';
import useData from '../hooks/useData.js';
import { useParams } from "react-router-dom";
import { renderShortDate } from "../components/Utils.js";
import { Standard, Sidebar } from "../layouts";

const ViewData = () => {
  const context = useContext(UserContext);
  const [register, setRegister] = useState({});
  const [sourceFilter, setSourceFilter] = useState({});
  const [columns, setColumns] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const { model: modelSignals } = useSignals(context.token);
  const { model: modelData } = useData(context.token);

  const { id: idSignal } = useParams();

  useEffect(() => {
    async function fetchData() {
      let reg = await modelSignals.getRegister({
        _id: idSignal,
        token: context.token
      });

      let flt = {}
      if (reg.signals.length === 1) {
        flt = JSON.parse(reg.signals[0].filter);
      }
      else {
        let subflsts = []
        for (let s of reg.signals) {
          let sf = JSON.parse(s.filter);
          if (s.fromDate && s.toDate) {
            sf['$and'] = [
              { [reg.dateField]: { $gte: s.fromDate } },
              { [reg.dateField]: { $lte: s.toDate } }
            ]
          }
          else if (s.fromDate) {
            sf[reg.dateField] = { $gte: s.fromDate }
          }
          else if (s.toDate) {
            sf[reg.dateField] = { $lte: s.toDate }
          }
          subflsts.push(sf);
        }
        flt = { $or: subflsts };
      }

      console.log('flt', flt)
      let cols = [
        {
          title: 'Fecha',
          dataIndex: reg.dateField,
          sorter: true,
          search: false,
          render: (v) => renderShortDate(v)
        },
        {
          title: 'Valor',
          dataIndex: reg.dateField,
          sorter: true,
          search: false,
          render: (v, row) => {
            let sgn = reg.signals.find(s =>
              (!s.fromDate || s.fromDate <= v)
              && (!s.toDate || s.toDate >= v))
            if (sgn) {
              return row[sgn.field]
            }
            return ''
          }
        },
      ]

      setColumns(cols);
      setSourceFilter(flt);
      setRegister(reg);
      setLoaded(true);
    }

    fetchData();

  }, [idSignal])


  const { Title } = Typography;

  return <Standard
    header={<Title>Datos de la señal {register.id}</Title>}
    left={<Sidebar routes={context.routes.filter(r => r.bigIcon)} />}
  >
    <DatatableMongo
      sourceFilter={sourceFilter}
      columns={columns}
      model={modelData}
      collection={register.collection}
      keyField={register.fieldDate}
      token={context.token}
    />
  </Standard>;

};

export default ViewData;
