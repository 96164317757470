import React, { useContext } from "react";
import { Card, Button, Result } from "antd";
import { UserContext } from '../others/UserContext';
import { Main } from "../layouts";
import { Link } from "react-router-dom";
import Colors from '../colors.json';

const Home = () => {
  const context = useContext(UserContext);

  let buttons = context.routes.filter( b => b.bigIcon );
  return (
    <Main>
      <div style={{height:`${(Math.floor((buttons.length - 1) / 4) + 1)*60}px`}}></div>
      {
        [...Array(Math.floor((buttons.length - 1) / 4) + 1).keys()].map(row =>
          <div key={`R-${row}`} className="flex w-full justify-center items-center">
            {
              Array.from({length: Math.min(4,buttons.length-4*row)}, (x, i) => i+4*row).map((column) => {
                return <Link
                  key={`C-${row}-${column}`}
                  style={{ margin: '20px' }}
                  to={buttons[column].path}>
                  <div
                    style={{
                      backgroundColor: Colors.primary.background,
                      paddingTop: '30px',
                      paddingBottom: '10px',
                      paddingLeft: '10px',
                      paddingRight: '10px'
                    }}
                  >
                    <Card
                      style={{ width: 140 }}
                      bordered={false}
                      cover={
                        buttons[column].bigIcon
                      }
                      bodyStyle={{
                        backgroundColor: Colors.primary.background,
                        color: Colors.primary.foreground,
                        textAlign: 'center',
                        fontSize: 18,
                        fontWeight: 'bold',
                        marginTop: '-1px',
                        marginInlineStart: '-1px',
                        marginInlineEnd: '-1px'
                      }}
                    >
                      {
                        buttons[column].label
                      }
                    </Card>
                  </div>
                </Link>
              })
            }
          </div>
        )
      }

    </Main>
  );
};

export default Home;
