import React from 'react';
import useMongo from './useMongo';
import { DateTime } from 'luxon';
import Utils from '../others/Utils';
import { filterOptions, latlonToString, renderOptions, renderTrueFalse, yesNoOptions } from '../components/Utils';


const featureTypeOptions = [
  {
    label: 'Aforo en marco',
    value: 'control',
  },
  {
    label: 'Aforo en canal',
    value: 'channel',
  },
  {
    label: 'Balsa',
    value: 'pond',
  },
  {
    label: 'Central Hidroeléctrica',
    value: 'hydroelectric',
  },
  {
    label: 'Estación meteorológica',
    value: 'station',
  },
  {
    label: 'Impulsión',
    value: 'impulsion',
  },
  {
    label: 'Marcos de control',
    value: 'frame',
  },
  {
    label: 'Presa',
    value: 'dam',
  },
  {
    label: 'Piezómetro',
    value: 'piezometer',
  },
  {
    label: 'Pluviómetro',
    value: 'pluviometer',
  }
]

const featureClassOptions = [
  {
    label: 'IFAPA',
    value: 'ifapa',
  },
  {
    label: 'SAIH',
    value: 'saih',
  },
  {
    label: 'Piezómetro',
    value: 'piezometer',
  }
]

const useFeatures = (token) => {
  const { model, columns, components, api, axios, authHeader } = useMongo({
    database: 'chg',
    collection: 'features',
    columns: [
      {
        id: 'id',
        title: 'Código',
        dataIndex: 'id',
        sorter: true,
        search: true,
        type: 'string',
        span: 2,
      },
      {
        id: 'featureName',
        title: 'Nombre',
        dataIndex: 'featureName',
        sorter: true,
        search: true,
        type: 'string',
        span: 6,
      },
      {
        id: 'featureType',
        title: 'Tipo',
        dataIndex: 'featureType',
        sorter: true,
        filters: filterOptions(featureTypeOptions),
        render: (v) => renderOptions(v, featureTypeOptions),
        type: 'options',
        options: featureTypeOptions,
        span: 4
      },
      {
        id: 'featureClass',
        title: 'Clase',
        dataIndex: 'featureClass',
        sorter: true,
        filters: filterOptions(featureClassOptions),
        render: (v) => renderOptions(v, featureClassOptions),
        type: 'options',
        options: featureClassOptions,
        span: 4
      },
      {
        id: 'latlon',
        title: 'Lat Lon',
        dataIndex: 'latlon',
        render: (v) => latlonToString(v),
        disabled: true,
        type: 'string',
        span: 4
      },
      {
        id: 'subbasin_id',
        title: 'Subcuenca',
        dataIndex: 'subbasin_id',
        integer: true,
        search: true,
        disabled: true,
        type: 'string',
        span: 2,
      },
      {
        id: 'active',
        title: 'Activo',
        dataIndex: 'active',
        sorter: true,
        filters: filterOptions(yesNoOptions('Activo', 'Inactivo')),
        render: (v) => renderTrueFalse(v),
        type: 'boolean',
        span: 2
      },
      {
        id: 'point',
        title: 'X Y UTM30',
        dataIndex: 'point',
        render: (v) => {
          if( Array.isArray(v) ) {
            return `${v[0]},${v[1]}`
          }
          return ''
        },
        type: 'point',
        span: 4
      },
      {
        id: 'base',
        omit: 'true'
      }
    ],
    components: [],
    //
    // https://www.juntadeandalucia.es/agriculturaypesca/ifapa/riaws/datosdiarios/41/13/2024-03-01/2024-11-16/false

  });


  model.addSignalsData = async ({ feature, fromDate, toDate, token, modelSignals }) => {

    if (feature.featureClass === 'ifapa') {
      try {
        let ifapaId = feature.id.replaceAll('_', '/');
        let fd = DateTime.fromISO(fromDate);
        let td = (toDate ? DateTime.fromISO(toDate) : DateTime.now().minus({ days: 1 }))

        let fdf = fd.toFormat('yyyy-MM-dd')
        let tdf = td.toFormat('yyyy-MM-dd');

        // Get count 
        let filter = {
          station_id: feature.id,
          $and: [
            { date: { $gte: fdf } },
            { date: { $lte: tdf } }
          ]
        }

        let count = await model.getCount({
          collection: 'ifapa_data',
          filter,
          token
        })

        let url = `https://www.juntadeandalucia.es/agriculturaypesca/ifapa/riaws/datosdiarios/${ifapaId}/${fd.toFormat('yyyy-MM-dd')}/${td.toFormat('yyyy-MM-dd')}/false`;

        let response = await axios.get(url, {
          responseType: 'json',
        });

        let registers = response.data;
        registers.forEach(reg => {
          reg.date = reg.fecha;
          reg.id = `${feature.id}:${reg.fecha}`;
          reg.station_id = feature.id;
        });

        if (count.count === 0) {
          return model.create({
            collection: 'ifapa_data',
            data: registers,
            token
          })
        }
        else {
          // Ir añadiendo cada uno
          let c = 0;
          let u = 0;
          for (let register of registers) {
            let count_reg = await model.getCount({
              collection: 'ifapa_data',
              filter: { id: register.id },
              token
            })
            if (count_reg.count > 0) {
              u++;
              await model.updateRegister({
                collection: 'ifapa_data',
                register,
                token
              })
            }
            else {
              c++;
              await model.create({
                collection: 'ifapa_data',
                data: register,
                token
              })
            }
          }
          return {
            updated: u,
            created: c
          }
        }
      }
      catch (err) {
        return {
          error: true,
          message: `Error: ${err.message}`
        }
      }
    }
    else if (feature.featureClass === 'saih') {
      try {
        let signals = await modelSignals.getList({
          filter: {
            featureId: feature.id
          },
          projection: {
            id: 1
          },
          token: token
        })
        let fd = DateTime.fromISO(fromDate);
        let td = (toDate ? DateTime.fromISO(toDate) : DateTime.now().minus({ days: 1 }))
        let fdf = encodeURIComponent(fd.toFormat('dd/MM/yyyy'));
        let tdf = encodeURIComponent(td.toFormat('dd/MM/yyyy'));
        let url = `${api}_getSAIH?signals=${signals.map(s => encodeURIComponent(s.id)).join(',')}&fromDate=${fdf}&toDate=${tdf}`;
        console.log('url',url)
        return axios({
          method: 'get',
          url: url,
          responseType: 'json',
          headers: {
            [authHeader]: `Bearer ${token}`
          }
        });
      }
      catch (err) {
        return {
          error: true,
          message: `Error: ${err.message}`
        }
      }
    }
    else {
      return { error: true, message: 'No implementado' }
    }
  };

  return { model, columns, components, api, axios, authHeader  }
}

export { useFeatures as default, featureTypeOptions, featureClassOptions };