import React, { useState } from "react";
import { Button, Form, Layout, Switch } from "antd";
import { headerStyle, contentStyle, footerStyle, layoutStyle, siderLeftStyle, siderRightStyle } from "../pages/styles.js";
import { MenuOutlined } from "@ant-design/icons";

function Standard({ children, header, headerRight, left, right }) {
  const { Header, Footer, Sider, Content } = Layout;
  const [showLeftSider, setShowLeftSider] = useState(false);
  const [showRightSider, setShowRightSider] = useState(false);

  return (
    <Layout style={layoutStyle}>
      <Header style={headerStyle}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row'
          }}
        >{
            left &&
            <Button
              style={{marginTop:'1em',marginRight:'1em'}}
              type={showLeftSider ? "default" : "primary"}
              onClick={() => setShowLeftSider(prev => !prev)}>
              <MenuOutlined />
            </Button>
          }
          {header}
          <div style={{
            flexGrow: 1
          }}>
          </div>
          {headerRight}
          {
            right &&
            <Button
              style={{marginTop:'1em',marginleft:'1em'}}
              type={showRightSider ? "default" : "primary"}
              onClick={() => setShowRightSider(prev => !prev)}>
              <MenuOutlined />
            </Button>
          }
        </div>
      </Header>
      <Layout>
        {
          showLeftSider &&
          <Sider width="15%" style={siderLeftStyle}>
            {left}
          </Sider>
        }
        <Content style={contentStyle}>
          {children}
        </Content>
        {showRightSider &&
          <Sider width="15%" style={siderRightStyle}>
            {right}
          </Sider>}
      </Layout>
      <Footer style={footerStyle}><b>A.Guerrero &copy; {new Date().getFullYear()}</b></Footer>
    </Layout>
  );
}

export default Standard