import React from "react";
import { Input, Button, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { DateTime } from "luxon";

function convertDDToDMS(D) {
  D = Math.abs(D)
  return [0 | D, '°', 0 | (D = D + 1e-4) % 1 * 60, "'", 0 | D * 60 % 1 * 60, '"'].join('');
}

const filterOptions = ( options ) => (
  options.map(({label,value, ...other}) => ({
    ...other,
    text: label,
    value
  }))
)

const getColumnSearchProps = (dataIndex) => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close, ...other }) => {
    return <div
      style={{
        padding: 8,
      }}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <Input
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => {
          confirm()
        }}
        style={{
          marginBottom: 8,
          display: 'block',
        }}
      />
      <Space>
        <Button
          type="primary"
          onClick={confirm}
          icon={<SearchOutlined />}
          size="small"
          style={{
            width: 90,
          }}
        >
          Busca
        </Button>
        <Button
          onClick={() => {
            clearFilters();
            confirm();
          }}
          size="small"
          style={{
            width: 90,
          }}
        >
          Reset
        </Button>
        <Button
          type="link"
          size="small"
          onClick={() => {
            confirm({
              closeDropdown: false,
            });
          }}
        >
          Filtra
        </Button>
        <Button
          type="link"
          size="small"
          onClick={() => {
            close();
          }}
        >
          Cierra
        </Button>
      </Space>
    </div>
  },
  filterIcon: (filtered) => (
    <SearchOutlined
      style={{
        color: filtered ? '#1677ff' : undefined,
      }}
    />
  ),
});

function getSearch(value, column) {
  let v = Array.isArray(value) ? value[0] : value;
  if (column.integer) {
    return parseInt(v);
  }
  if (column.float) {
    return parseFloat(v);
  }
  return {
    '$regex': `.*${v}.*`,
    '$options': 'i'
  }
}

function latlonToString(latlon) {
  if( !latlon ) return ''
  return `${latlon[0] < 0 ? 'S' : 'N'}${convertDDToDMS(latlon[0])} ${latlon[1] < 0 ? 'O' : 'E'}${convertDDToDMS(latlon[1])}`
}

const renderShortDate = (value) => (value ? `${DateTime.fromISO(value).toLocaleString(DateTime.DATE_SHORT)}` : '');

const renderTrueFalse = (value, yes='Sí', no = 'No') => (value ? yes : no);

const renderOptions = (value, options = [] ) => {
  let opt = options.find(o => o.value === value);
  return opt ? opt.label : value
}

const yesNoOptions = ( yes='Sí', no='No') => (
  [
    {
      label: yes,
      value: true
    },
    {
      label: no,
      value: false
    }
  ]);


export { convertDDToDMS, filterOptions, getColumnSearchProps, getSearch, latlonToString, 
  renderShortDate, renderTrueFalse, renderOptions, yesNoOptions };