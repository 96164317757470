import React from 'react';
import useMongo from './useMongo';

import { filterOptions, renderOptions, renderShortDate, renderTrueFalse, yesNoOptions } from '../components/Utils';

const limitOptions = [
  {
    label: 'Dentro',
    value: 'inside'
  },
  {
    label: 'Cerca',
    value: 'near'
  }
]

const typeOptions = [
  {
    label: 'Caudal',
    value: 'flow',
    featureTypes: [ 'dam', 'impulsion', 'frame', 'hydroelectric']
  },
  {
    label: 'Humedad',
    value: 'humidity',
    featureTypes: [ 'station' ] 
  },
  {
    label: 'Nivel',
    value: 'level',
    featureTypes: [ 'control', 'pond', 'dam', 'frame', 'impulsion', 'hydroelectric' ] 
  },
  {
    label: 'Piezómetro',
    value: 'piezometer',
    featureTypes: [ 'piezometer' ]
  },
  {
    label: 'Potencia activa',
    value: 'power',
    featureTypes: [ 'hydroelectric' ]
  },
  {
    label: 'Radiación',
    value: 'radiation',
    featureTypes: [ 'station' ] 
  },
  {
    label: 'Lluvia',
    value: 'rain',
    featureTypes: [ 'station', 'dam', 'pluviometer', 'frame', 'impulsion', 'hydroelectric' ] 
  },
  {
    label: 'Temperatura',
    value: 'temperature',
    featureTypes: [ 'station', 'dam', 'hydroelectric'  ] 
  },
  {
    label: 'Volumen',
    value: 'volume',
    featureTypes: [ 'dam', 'hydroelectric' ] 
  }
]

const useSignals = (token) => {
  const { model, columns, components, api, axios, authHeader } = useMongo({
    database: 'chg',
    collection: 'signals',
    columns: [{
      id:'id',
      title: 'Nombre',
      dataIndex: 'id',
      sorter: true,
      search: true,
      type: 'string',
      span:6,
    },
    {
      id:'name',
      title: 'Descripción',
      dataIndex: 'name',
      sorter: true,
      search: true,
      type: 'string',
      span:12,
    },
    {
      id:'type',
      title: 'Tipo',
      dataIndex: 'type',
      sorter: true,
      filters: filterOptions(typeOptions),
      render: (v) => renderOptions(v, typeOptions),
      type: 'options',
      options: typeOptions,
      span: 4
    },
    {
      id:'isM',
      title: 'Modelable',
      dataIndex: 'isM',
      sorter: true,
      filters: filterOptions(yesNoOptions()),
      render: (v) => renderTrueFalse(v),
      type: 'boolean',
      span: 2
    },
    {
      id: 'featureId',
      title: 'Elemento',
      dataIndex: 'featureId',
      sorter: true,
      search: true,
      type: 'string',
      disabled: true,
      span: 6
    },
    {
      id: 'collection',
      title: 'Colección',
      dataIndex: 'collection',
      sorter: true,
      filterSearch: false,
      autoFilter: true,
      type: 'string',
      span: 6
    },
    {
      id: 'dateField',
      title: 'Campo fecha',
      dataIndex: 'dateField',
      sorter: true,
      type: 'string',
      span: 6
    },
    {
      id: 'limit',
      title: 'Límite',
      dataIndex: 'limit',
      sorter: true,
      filters: filterOptions(limitOptions),
      render: (v) => renderOptions(v, limitOptions),
      type: 'options',
      options: limitOptions,
      span: 4
    },
    {
      id: 'active',
      title: 'Activo',
      dataIndex: 'active',
      sorter: true,
      filters: filterOptions(yesNoOptions('Activa','Inactiva')),
      render: (v) => renderTrueFalse(v),
      type: 'bool',
      span: 2
    },
    {
      id: 'signals',
      title: 'Señales',
      dataIndex: 'signals',
      span: 24,
      render: (v) => <div style={{display:'flex', flexFlow:'column'}}>
          { Array.isArray(v) && v.map( (s,index) => <div key={`K${index}`} style={{display:'flex', flexFlow:'row'}}>
            { 
              s.fromDate && 
              <span>{`de ${renderShortDate(s.fromDate)}`}</span>
            }
            { 
              s.toDate && 
              <span style={{marginLeft:'0.5em'}}>{`a ${renderShortDate(s.toDate)}`}</span>
            }
            <span style={{marginLeft:'0.5em'}}>{`${s.field} ${s.filter}`}</span>
            </div>)
          }
        </div>,
      type: 'table',
      columns: [
        {
          id: 'field',
          title: 'Campo',
          dataIndex: 'field',
          type: 'string',
        },
        {
          id: 'filter',
          title: 'Filtro',
          dataIndex: 'filter',
          type: 'string',
        },
        {
          id: 'fromDate',
          title: 'Desde fecha',
          dataIndex: 'fromDate',
          type: 'date',
          render: (v) => renderShortDate(v)
        },
        {
          id: 'toDate',
          title: 'Hasta fecha',
          dataIndex: 'toDate',
          type: 'date',
          render: (v) => renderShortDate(v)
        },
      ]
    },],
    components: [

    ]
  });

  return { model, columns, components, api, limitOptions, typeOptions }
}

export {useSignals as default, typeOptions};