import React, { useEffect, useState, useContext, useMemo } from 'react'
import {
  MapContainer, TileLayer, Marker, Popup, GeoJSON, CircleMarker,
  LayerGroup, LayersControl, Tooltip, useMap, useMapEvents,
  FeatureGroup
} from 'react-leaflet'
import { HashLink } from 'react-router-hash-link';
import { Link } from "react-router-dom";
import { UserContext } from '../others/UserContext';
import useSignals from '../hooks/useSignals';
import useBestModels from '../hooks/useBestModels';
import useVwFeatSignals from '../hooks/useVwFeatSignals';
import useControls from '../hooks/useControls';
import useSubbasins from '../hooks/useSubbasins';
import useTrees from '../hooks/useTrees';
import PacmanLoader from "react-spinners/PacmanLoader";
import { Affix, Typography } from "antd";
import '../others/proj4-module'
import Utils from '../others/Utils';
//import { iconMarker } from '../icons/iconMarker';
import L from 'leaflet';
import { Button, Modal, Image } from 'antd';
import { Standard } from '../layouts';
const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "#50C878",
};

const iconMarker = (color) => L.divIcon({
  className: "custom-icon-marker",
  iconSize: L.point(40, 40),
  html: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class="marker"><path fill-opacity="0.25" d="M16 32s1.427-9.585 3.761-12.025c4.595-4.805 8.685-.99 8.685-.99s4.044 3.964-.526 8.743C25.514 30.245 16 32 16 32z"/><path stroke="#fff" fill="${color}" d="M15.938 32S6 17.938 6 11.938C6 .125 15.938 0 15.938 0S26 .125 26 11.875C26 18.062 15.938 32 15.938 32zM16 6a4 4 0 100 8 4 4 0 000-8z"/></svg>`,
  iconAnchor: [12, 24],
  popupAnchor: [9, -26],
})

function getColor({ signals }) {
  if (!Array.isArray(signals) || signals.length == 0) {
    return '#54B4D3'
  }

  let best = signals.reduce((prev, current) => {
    if (current.best?.r2 > prev) return current.best?.r2;
    return prev;
  }, -1000)
  if (best < -999) return '#54B4D3'
  if (best < 0.4) return '#DC4C64'
  if (best < 0.6) return '#E4A11B'
  return '#14A44D'
}

function Basic({ feature, api, token }) {
  return <><a href={`/feature/${feature._id}`} rel="noreferrer" target='_blank'>{feature.id}</a>&nbsp;{feature.featureName.replaceAll(feature.id, '')}
    {
      (feature.signals || []).map(s =>
        <ul key={s.id}>
          <li>{s.id}&nbsp;{s.name}
            {
              s.best &&
              <><span>-&gt;{s.best.name}:&nbsp;{Math.round(s.best.r2 * 100) / 100}</span>
                <Image
                  width={200}
                  src={`${api}_binaries/${s.best.figure}?database=chg&token=${token}`}
                /></>
            }
          </li>
        </ul>
      )
    }
    {
      feature.notes &&
      <ul><li>NOTAS:{feature.notes}</li></ul>
    }
  </>
}

function Control({ feature, api, token }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [graph, setGraph] = useState('');
  let icon = iconMarker(getColor(feature))
  return <><Marker
    icon={icon}
    position={feature.latlon}>
    <Popup>
      <a href={`/dashboard#${feature.id}`} rel="noreferrer" target='_blank'>Grs</a><br />
      <Basic
        feature={feature}
        api={api}
        token={token} />
    </Popup>
    <Tooltip>{feature.featureName}</Tooltip>
  </Marker>
    <Modal title="Gráfica" open={isModalOpen}
      onOk={() => {
        setIsModalOpen(false);
      }} onCancel={() => {
        setIsModalOpen(false);
      }}
      footer={[
        <Button
          key='submit'
          type='primary'
          onClick={() => {
            setIsModalOpen(false);
          }}>OK</Button>
      ]}
    >
      <img alt='gráfica' src={graph} height={200} />
    </Modal>
  </>
}

function IfapaStation({ feature, api, token }) {
  return <CircleMarker
    pathOptions={{
      color: 'green',
      fillColor: '#f03',
      fillOpacity: 0.5,
    }}
    center={feature.latlon}
    radius={10}
  >
    <Popup>
      <Basic
        feature={feature}
        api={api}
        token={token} />
    </Popup>
    <Tooltip>{feature.id} {feature.featureName}</Tooltip>
  </CircleMarker>
}

function Dam({ feature, api, token }) {
  return <CircleMarker
    pathOptions={{
      color: 'blue',
      fillColor: '#00F',
      fillOpacity: 0.5,
    }}
    center={feature.latlon}
    radius={10}
  >
    <Popup>
      <Basic
        feature={feature}
        api={api}
        token={token} />
    </Popup>
    <Tooltip>{feature.id} {feature.featureName.replaceAll(feature.id, '')}</Tooltip>
  </CircleMarker>
}

function Piezometer({ feature, api, token }) {
  return <CircleMarker
    pathOptions={{
      color: 'orange',
      fillColor: '#E4A11B',
      fillOpacity: 0.5,
    }}
    center={feature.latlon}
    radius={10}
  >
    <Popup>
      <Basic
        feature={feature}
        api={api}
        token={token} />
    </Popup>
    <Tooltip>{feature.id} {feature.featureName}</Tooltip>
  </CircleMarker>
}

function Impulsion({ feature, api, token }) {
  return <CircleMarker
    pathOptions={{
      color: 'gray',
      fillColor: '#333333',
      fillOpacity: 0.5,
    }}
    center={feature.latlon}
    radius={10}
  >
    <Popup>
      <Basic
        feature={feature}
        api={api}
        token={token} />
    </Popup>
    <Tooltip>{feature.id} {feature.featureName}</Tooltip>
  </CircleMarker>
}

function Channel({ feature, api, token }) {
  return <CircleMarker
    pathOptions={{
      color: 'gray',
      fillColor: '#333333',
      fillOpacity: 0.5,
    }}
    center={feature.latlon}
    radius={10}
  >
    <Popup>
      <Basic
        feature={feature}
        api={api}
        token={token} />
    </Popup>
    <Tooltip>{feature.id} {feature.featureName}</Tooltip>
  </CircleMarker>
}

function Frame({ feature, api, token }) {
  return <CircleMarker
    pathOptions={{
      color: 'gray',
      fillColor: '#333333',
      fillOpacity: 0.5,
    }}
    center={feature.latlon}
    radius={10}
  >
    <Popup>
      <Basic
        feature={feature}
        api={api}
        token={token} />
    </Popup>
    <Tooltip>{feature.id} {feature.featureName}</Tooltip>
  </CircleMarker>
}

function Pluviometer({ feature, api, token }) {
  return <CircleMarker
    pathOptions={{
      color: 'gray',
      fillColor: '#333333',
      fillOpacity: 0.5,
    }}
    center={feature.latlon}
    radius={10}
  >
    <Popup>
      <Basic
        feature={feature}
        api={api}
        token={token} />
    </Popup>
    <Tooltip>{feature.id} {feature.featureName}</Tooltip>
  </CircleMarker>
}

function Pond({ feature, api, token }) {
  return <CircleMarker
    pathOptions={{
      color: 'gray',
      fillColor: '#333333',
      fillOpacity: 0.5,
    }}
    center={feature.latlon}
    radius={10}
  >
    <Popup>
      <Basic
        feature={feature}
        api={api}
        token={token} />
    </Popup>
    <Tooltip>{feature.id} {feature.featureName}</Tooltip>
  </CircleMarker>
}

function Hydroelectric({ feature, api, token }) {
  return <CircleMarker
    pathOptions={{
      color: 'gray',
      fillColor: '#333333',
      fillOpacity: 0.5,
    }}
    center={feature.latlon}
    radius={10}
  >
    <Popup>
      <Basic
        feature={feature}
        api={api}
        token={token} />
    </Popup>
    <Tooltip>{feature.id} {feature.featureName}</Tooltip>
  </CircleMarker>
}

function FeatureSignal({ feature, api, token }) {
  switch (feature.featureType) {
    case 'control':
      return <Control feature={feature} api={api} token={token} />
    case 'impulsion':
      return <Impulsion feature={feature} />
    case 'dam':
      return <Dam feature={feature} />
    case 'piezometer':
      return <Piezometer feature={feature} />
    case 'station':
      return <IfapaStation feature={feature} />
    case 'channel':
      return <Channel feature={feature} />
    case 'frame':
      return <Frame feature={feature} />
    case 'pluviometer':
      return <Pluviometer feature={feature} />
    case 'pond':
      return <Pond feature={feature} />
    case 'hydroelectric':
      return <Hydroelectric feature={feature} />
    default:
      return <div></div>
  }
}

function FeatureLayer({ feature, trees, subbasins, features, api, token }) {
  let tr = trees.find(t => t.id == feature.subbasin_id)

  return <LayersControl.Overlay
    name={feature.id}
  >
    <FeatureGroup>
      <FeatureSignal key={`${Utils.UUID()}`} feature={feature} api={api} token={token} />
      {tr &&
        (tr.tree || []).map(t => {
          let sb = subbasins.find(s => s.id == t.id);
          if (sb) {
            return <GeoJSON key={`${Utils.UUID()}`} data={sb.geometry} />
          }
          return <div key={`${Utils.UUID()}`}></div>
        })
      }
      {
        (feature.affectingFeatures || []).filter(c => c != feature.id).map(featureId => {
          let feature = features.find(s => s.id == featureId);
          if (feature) {
            return <FeatureSignal key={`${Utils.UUID()}`} feature={feature} api={api} token={token} />
          }
          return <div key={`${Utils.UUID()}`}></div>
        })
      }
    </FeatureGroup>
  </LayersControl.Overlay>
}

function ZoomToLayerExtent() {
  const map = useMapEvents({
    overlayadd(e) {
      let bounds = e.layer.getBounds();
      map.fitBounds(bounds)
    }
  })
  return null
}

export default function Map() {
  const [loaded, setLoaded] = useState(false);
  const [loadingText, setLoadingText] = useState('Cargando...');
  //const [controls, setControls] = useState([]);
  //const [piezos, setPiezos] = useState([]);
  const [features, setFeatures] = useState([]);
  const [subbasins, setSubbasins] = useState([]);
  const [trees, setTrees] = useState([]);

  const context = useContext(UserContext);
  //const { model: modelSignals } = useSignals(context.token);
  //const { model: modelControls } = useControls(context.token);
  const { model: modelBest } = useBestModels(context.token);
  const { model: modelFeatures, api } = useVwFeatSignals(context.token);
  const { model: modelSubbasins } = useSubbasins(context.token);
  const { model: modelTrees } = useTrees(context.token);

  const { Title } = Typography;

  useEffect(() => {
    async function fetchData() {
      window.proj4.defs("EPSG:32630", "+proj=utm +zone=30 +datum=WGS84 +units=m +no_defs +type=crs");

      setLoadingText('Cargando subcuencas...')
      let sbs = await modelSubbasins.getList({
        filter: {},
        projection: {
          _id: 0,
          id: 1,
          upstreams: 1,
          basin: 1,
          stations: 1,
          geometry: 1
        },
        token: context.token
      })

      setLoadingText('Cargando features...')
      let fs = await modelFeatures.getList({
        filter: {}, // {subbasin_id:{$gt:0}},
        projection: {
          _id: 1,
          id: 1,
          latlon: 1,
          subbasin_id: 1,
          featureName: 1,
          featureType: 1,
          featureClass: 1,
          affectingFeatures: 1,
          affectingSignals: 1,
          signals: 1
        },
        sort: {
          id: 1
        },
        token: context.token
      })

      setLoadingText('Cargando best models...')
      let bms = await modelBest.getList({
        filter: {},
        token: context.token
      })

      for (let feat of fs) {
        for (let signal of feat.signals) {
          signal.best = bms.find(m => m.signal_id == signal.id)
        }
      }

      setLoadingText('Cargando árboles...')
      let trs = await modelTrees.getList({
        filter: {},
        projection: {
          _id: 0
        },
        token: context.token
      })

      //console.log('controls', cs)
      //console.log('piezos', ps)
      console.log('features', fs)
      setTrees(trs);
      //setControls(cs);
      //setPiezos(ps);
      setFeatures(fs);
      setSubbasins(sbs);
      setLoaded(true);
    }

    fetchData();
  }, [])

  return <Standard
    header={<Title>Mapa</Title>} >
    {
      !loaded &&
      <Affix
        style={{ position: 'absolute', top: '40%', left: '50%' }}
      ><div><div>{loadingText}</div><PacmanLoader
        color={'#50C878'}
        loading={true}
        cssOverride={override}
        size={50}
        aria-label="Loading Spinner"
        data-testid="loader"
      /></div>
      </Affix>
    }
    {loaded && <MapContainer
      center={[37.8, -3.5]}
      zoom={8}
      scrollWheelZoom={false}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url='https://tile.openstreetmap.org/{z}/{x}/{y}.png'
      />
      <ZoomToLayerExtent />
      <LayersControl position="topright">
        <LayersControl.Overlay name="SAIH" key='L-SAIH'>
          <FeatureGroup>
            {
              features.map(s => <FeatureSignal key={`SAIH-${s.id}`} feature={s} api={api} token={context.token} />)
            }
          </FeatureGroup>
        </LayersControl.Overlay>
        {
          features.map(c => <FeatureLayer
            key={`L-${c.id}`}
            feature={c}
            features={features}
            trees={trees}
            subbasins={subbasins}
            api={api}
            token={context.token}
          />)
        }
        <LayersControl.Overlay name="Subcuencas" key={`L-SUB`}>
          <FeatureGroup>
            {
              subbasins.map(s => <GeoJSON key={`${Utils.UUID()}`} data={s.geometry} />)
            }
          </FeatureGroup>
        </LayersControl.Overlay>
        <LayersControl.Overlay name="IFAPA" key={`L-IFA`}>
          <FeatureGroup>
            {
              features.filter(f => f.featureType == 'station').map(s => <FeatureSignal
                key={`IFAPA-${s.id}`}
                feature={s}
                api={api} token={context.token} />)
            }
          </FeatureGroup>
        </LayersControl.Overlay>
        <LayersControl.Overlay name="Embalses" key={`L-DAM`}>
          <FeatureGroup>
            {
              features.filter(f => f.featureType == 'dam').map(s => <FeatureSignal
                key={`DAM-${s.id}`}
                feature={s}
                api={api} token={context.token} />)
            }
          </FeatureGroup>
        </LayersControl.Overlay>
        <LayersControl.Overlay name="Piezómetros" key={`L-PIEZO`}>
          <FeatureGroup>
            {
              features.filter(f => f.featureType == 'piezometer').map(s => <FeatureSignal
                key={`PIEZO-${s.id}`}
                feature={s}
                api={api} token={context.token} />)
            }
          </FeatureGroup>
        </LayersControl.Overlay>
      </LayersControl>
    </MapContainer>
    }
  </Standard>
}