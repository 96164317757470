import React from 'react';
import useMongo from './useMongo';
import { Image } from 'antd';
import { yesNoOptions, renderOptions, renderTrueFalse, filterOptions, renderShortDate} from '../components/Utils.js';

const useModels = (token) => {
  const { model, columns, components, api, axios, authHeader } = useMongo({
    database: 'chg',
    collection: 'models',
    columns: [{
      id:'name',
      title: 'Nombre',
      dataIndex: 'name',
      sorter: true,
      type: 'string',
      autoFilter: true,
      span:6,
      },{
      id:'modelClass',
      title: 'Clase',
      dataIndex: 'modelClass',
      sorter: true,
      type: 'string',
      autoFilter: true,
      span:6,
    },
    {
      id: 'signal_id',
      title: 'Señal',
      dataIndex: 'signal_id',
      sorter: true,
      type: 'string',
      autoFilter: true,
      disabled: true,
      span: 6
    },
    {
      id: 'quarter',
      title: 'Trimestre',
      dataIndex: 'quarter',
      sorter: true,
      integer: true,
      type: 'slider',
      min: 0,
      max: 4,
      step: 1,
      autoFilter: true,
      span: 4
    },
    {
      id:'days',
      title: 'Días',
      dataIndex: 'days',
      sorter: true,
      integer: true,
      type: 'string',
      autoFilter: true,
      span: 4
    },
    {
      id:'date',
      title: 'Fecha',
      dataIndex: 'date',
      sorter: true,
      render: (v) => renderShortDate(v),
      type: 'date',
      span: 4
    },
    {
      id:'limit',
      title: 'Límite',
      dataIndex: 'limit',
      sorter: true,
      type: 'float',
      span: 1
    },
    {
      id:'version',
      title: 'Versión',
      dataIndex: 'version',
      sorter: true,
      integer: true,
      type: 'string',
      span: 1
    },
    {
      id:'r2',
      title: 'R2',
      dataIndex: 'r2',
      sorter: true,
      float: true,
      type: 'float',
      render: (v) => Math.round((v + Number.EPSILON) * 100) / 100,
      span: 1
    },
    {
      id:'figure',
      title: 'Figura',
      dataIndex: 'figure',
      sorter: false,
      type: 'figure',
      render: (v) => <Image
        width={200}
        src={`${api}_binaries/${v}?database=chg&token=${token}`}
      />,
      span: 4
    },
    {
      id:'figure_features',
      title: 'Figura Señales',
      dataIndex: 'figure_features',
      sorter: false,
      type: 'figure',
      render: (v) => <Image
        height={120}
        src={`${api}_binaries/${v}?database=chg&token=${token}`}
      />,
      span: 4
    },
    {
      id: 'rows',
      title: 'Filas',
      dataIndex: 'size',
      sorter: false,
      filters: filterOptions(yesNoOptions('Activo','Inactivo')),
      render: (v) => v[0],
      isInteger: true,
      type: 'string',
      span: 2
    },
    {
      id: 'active',
      title: 'Activo',
      dataIndex: 'active',
      sorter: true,
      filters: filterOptions(yesNoOptions('Activo','Inactivo')),
      render: (v) => renderTrueFalse(v),
      type: 'bool',
      span: 2
    },],
    components: []
  });

  return { model, columns, components, api, axios, authHeader  }
}

export default useModels;