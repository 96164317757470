import React, { useContext, useMemo } from "react";
import { Typography, Button } from "antd";
import { UserContext } from '../others/UserContext';
import DatatableMongo from "../components/DatatableMongo.js";
import useSignals from '../hooks/useSignals';
import { useLocation, Link } from "react-router-dom";
import { Standard, Sidebar } from "../layouts";

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const ViewSignals = () => {
  const context = useContext(UserContext);
  const { model: modelSignals, columns: colSignals, api } = useSignals(context.token);

  let query = useQuery();

  const { Title } = Typography;

  const sourceFilters = useMemo(() => {
    let featureId = query.get("featureId");
    if (featureId) {
      return {
        featureId
      }
    }
    return {}
  }, [])

  const columns = useMemo(() => (
    [
      ...Object.values(colSignals),
      {
        title: 'Acción',
        dataIndex: '_id',
        render: (v, row) => {
          return <><Link
            to={`/signal/${v}`}>
            <Button>Edita</Button>
          </Link>
            <Link
              to={`/data/${v}`}>
              <Button>Datos</Button>
            </Link>
            {row.base?._id && <Button
              onClick={() => {
                window.open(`${api}_fromFthToExcel/${row.base?._id}?token=${context.token}&database=chg&fromDate=${row.base.from_date}`, '_blank')
              }}
            >Descarga Base</Button>
            }
          </>
        }
      },
    ]), [])

  return <Standard
    header={<Title>Señales</Title>}
    left={<Sidebar routes={context.routes.filter(r => r.bigIcon)} />}
  >
    <DatatableMongo
      sourceFilter={sourceFilters}
      columns={columns}
      model={modelSignals}
      keyField='_id'
      token={context.token}
    />
  </Standard>
};

export default ViewSignals;
