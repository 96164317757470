import React, { useContext, useEffect, useState, useMemo } from "react";
import { Col, Typography, Button, Row } from "antd";
import { UserContext } from '../others/UserContext';
import useSignals from '../hooks/useSignals';
import RegisterForm from "../components/RegisterForm.js";
import { useLocation, useParams, Link } from "react-router-dom";
import useData from '../hooks/useData.js';
import useFeatures from '../hooks/useFeatures';
import useModels from '../hooks/useModels.js';
import { Standard, Sidebar } from "../layouts";
import { renderShortDate } from "../components/Utils.js";
import { DateTime } from "luxon";
import Colors from '../components/canvasColors.json';
import { useSnackbar } from "notistack";
import Datatable from "../components/Datatable.js";
import DatatableMongo from "../components/DatatableMongo.js";


function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

const ViewSignal = () => {
  const context = useContext(UserContext);
  const [register, setRegister] = useState({});
  const [feature, setFeature] = useState({});
  const [firstData, setFirstdata] = useState({});
  const [lastData, setLastdata] = useState({});
  const [loaded, setLoaded] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { model: modelSignals, columns } = useSignals(context.token);
  const { model: modelData } = useData(context.token);
  const { model: modelFeatures } = useFeatures(context.token);
  const { model: modelModels, columns: columnModels } = useSignals(context.token);

  const { Title } = Typography;

  const { id: idSignal } = useParams();
  let query = useQuery();

  const getLatestData = async () => {
    let feature = await modelFeatures.getList({
      filter: {
        id: register.featureId
      },
      token: context.token,
    })

    let fromDate = lastData[register.dateField] 
    ?  DateTime.fromISO(lastData[register.dateField]).plus({days:1})
    : DateTime.fromISO('2000-01-01');

    let changes = await modelFeatures.addSignalsData({
      feature: feature[0],
      fromDate: fromDate.toISO(),
      token: context.token,
      modelSignals
    })
    
    setLoaded(false);
    enqueueSnackbar(changes.data.message, { variant: changes.data.error ? 'error' : 'success' })
  };

  useEffect(() => {
    async function fetchData() {
      console.log('fetchData')
      let reg = {}
      if( idSignal ) {
        reg = await modelSignals.getRegister({
          _id: idSignal,
          token: context.token
        });
      }
      else {
        let signal_id = query.get("id");
        let regs = await modelSignals.getList({
          filter: {
            id: signal_id
          },
          token: context.token
        });
        if( regs.length>0 ) {
          reg = regs[0]
        }
      }

      let flt = {}
      if( !Array.isArray(reg.signals) ) {
        reg.signals = []
      }

      if (reg.signals.length === 1) {
        flt = JSON.parse(reg.signals[0].filter);
      }
      else if (reg.signals.length > 1){
        let subflsts = []
        for (let s of reg.signals) {
          let sf = JSON.parse(s.filter);
          if (s.fromDate && s.toDate) {
            sf['$and'] = [
              { [reg.dateField]: { $gte: s.fromDate } },
              { [reg.dateField]: { $lte: s.toDate } }
            ]
          }
          else if (s.fromDate) {
            sf[reg.dateField] = { $gte: s.fromDate }
          }
          else if (s.toDate) {
            sf[reg.dateField] = { $lte: s.toDate }
          }
          subflsts.push(sf);
        }
        flt = { $or: subflsts };
      }

      let latest = await modelData.getList({
        collection: reg.collection,
        limit: 1,
        filter: flt,
        sort: { [reg.dateField]: -1 },
        token: context.token
      })

      let first = await modelData.getList({
        collection: reg.collection,
        limit: 1,
        filter: flt,
        sort: { [reg.dateField]: 1 },
        token: context.token
      })

      let feats = await modelFeatures.getList({
        filter: {
          id: reg.featureId
        },
        projection: {
          _id: 1,
          id: 1
        },
        token: context.token
      })

      console.log('first',first)
      console.log('latest',latest)
      setLastdata(latest.length>0 ? latest[0] : {});
      setFirstdata(first.length>0 ? first[0] : {});
      setRegister(reg);
      setFeature(feats[0])
      setLoaded(true);
    }

    if (!loaded) {
      fetchData();
    }


  }, [idSignal, loaded])

  return <Standard
    header={<Title>Señales</Title>}
    headerRight={<>
      <Link
        to={`/feature/${feature._id}`}>
        <Button type='primary'>Elemento</Button>
      </Link>
      <Link
        to={`/data/${register._id}`}>
        <Button style={{ marginLeft:'1em'}} type='primary'>Datos</Button>
      </Link>
      <Button
        type='primary'
        onClick={getLatestData}
        style={{ marginLeft:'1em', marginTop: '1.2em', marginRight: '1em', backgroundColor: Colors.warning.backgroundColor }}>
        Actualiza datos
      </Button>
      <Link to={`/models?signal_id=${register.id}`}><Button disabled={!register.isM}>Modelos</Button></Link>
    </>}
    left={<Sidebar routes={context.routes.filter(r => r.bigIcon)} />}
  >
    {
      loaded &&
      <>
        <Row><Col>Primer dato:{renderShortDate(firstData[register.dateField])}</Col></Row>
        <Row><Col>Último dato:{renderShortDate(lastData[register.dateField])}</Col></Row>
        <Row><Col style={{height:'fit-content'}}><RegisterForm
          fields={columns}
          register={register}
          onChange={({ name, value }) => {
            setRegister(prev => ({
              ...prev,
              [name]: value
            }))
          }} /></Col></Row>
          {/*<Row>
            <Col><Title level={3}>Modelos</Title></Col>
          </Row>
          <Row>
            <Col style={{width:'100%'}}>
            <DatatableMongo
              columns={columnModels}
              model={modelModels}
              keyField='_id'
              token={context.token}
              sort={{ id: 1 }}
            />
            </Col>
          </Row>*/}
      </>
    }
  </Standard>
};

export default ViewSignal;
