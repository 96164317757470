import React, { useCallback, useContext, useEffect, useState, useRef, useMemo } from "react";
import { Affix, Card, Flex, Input, Modal, Button, Layout, Dropdown, Result, Space, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import PropTypes from 'prop-types';
import { getColumnSearchProps } from "./Utils";

const Datatable = ({ columns, dataSource = [], ...other }) => {
  const [localColumns, setLocalColumns] = useState([]);
  const [localData, setlocalData] = useState([]);

  const keyField = '_index';

  useEffect( () => {
    console.log('rebuild local data')
    let dt = [...dataSource]

    if(Array.isArray(dataSource) && dataSource.length>0
    && typeof dataSource[0] !==  'object' ) {
      dt = dataSource.map( v => ({
        value: v
      }))
    }
    
    dt.forEach((el,index) => {
      el._index = index
    });
    setlocalData(dt);
  }, [dataSource])

  useEffect(() => {
    async function rebuildCols() {
      console.log('rebuild cols')
      let cols = [
        ...columns
      ]

      for (let i = 0; i < cols.length; i++) {
        let col = cols[i];
        if (col.autoFilter) {
          let opts = new Set( dataSource.map(d => d[col.dataIndex]))
          col.filters = [...opts].map(o => ({
            text: o,
            value: o
          }))
        }
        else if (col.search) {
          cols[i] = {
            ...col,
            ...getColumnSearchProps(col.dataIndex)
          }
        }
      }

      setLocalColumns(cols);
    }

    rebuildCols();

  }, [columns])


  return <Table
    {...other}
    columns={localColumns}
    rowKey={(record) => record[keyField]}
    dataSource={localData}  
    pagination={{showTotal: (total) => `Total ${total} registros`}}
  />
}

Datatable.propTypes = {
  columns: PropTypes.array,
  dataSource: PropTypes.array,
};

export default Datatable;